@media (max-width: 780px) {
  .ocultar {
    display: none !important;
  }
}

@media (min-width: 780px) {
  .buscar {
    display: none !important;
  }
}

#icon-search {
  color: #006fba;
  font-size: 30px;
  top: 15px;
  left: 45%;
  padding-bottom: 18px;
}

